<template>
  <div>
    <div class="block md:hidden">
      <div class="relative flex justify-end p-4">
        <nuxt-img class="rounded-3xl" src="/row_of_trucks_mobile.png" alt="" />
      </div>
      <div class="absolute p-2 -mt-20 bg-white z-9 rounded-tr-lg w-[290px]">
        <p class="pl-2 text-xl font-medium text-gray-600">
          {{ $t('home.networkHint[0]') }}
          <span class="font-bold text-cblack">{{
            $t('home.networkHint[1]')
          }}</span>
          {{ $t('home.networkHint[2]') }}
        </p>
      </div>
    </div>

    <div class="hidden md:block">
      <div
        class="relative w-full h-64 bg-center bg-cover rounded-xl"
        style="background-image: url('/row_of_trucks_home.png')"
      >
        <div
          class="absolute max-w-sm p-6 transform -translate-y-1/2 bg-white rounded-lg lg:block top-1/2 left-8"
        >
          <p class="text-2xl font-medium text-gray-600">
            {{ $t('home.networkHint[0]') }}
            <span class="font-bold text-cblack">
              {{ $t('home.networkHint[1]') }}
            </span>
            {{ $t('home.networkHint[2]') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
