<template>
  <div>
    <div class="pb-16 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:rounded-2xl">
      <VHeader :title="$t('home.advantagesWithUs')" />
    </div>

    <div
      class="grid grid-cols-2 mx-auto text-center gap-y-4 gap-x-2 md:gap-8 lg:gap-24 max-w-7xl md:grid-cols-2 lg:grid-cols-4 justify-items-center"
    >
      <div
        v-for="item in advantagesList"
        :key="item.title"
        class="grid grid-rows-[auto_60px_1fr] gap-y-2 lg:gap-y-6 justify-items-center"
      >
        <component
          :is="item.icon"
          class="h-16 md:h-24 md:w-26 opacity-85"
          aria-hidden="true"
        />

        <p class="text-lg font-semibold max-w-32">
          {{ item.title }}
        </p>

        <div class="text-sm text-gray-600 max-w-52 lg:max-w-44">
          {{ item.description }}
          <span class="font-bold">{{ item.descriptionBold }}</span>
          {{ item.descriptionFinal }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AvailableTruck from '@/components/icons/AvailableTruck.vue'
import BookingIcon from '@/components/icons/BookingIcon.vue'
import FlexibleIcon from '@/components/icons/FlexibleIcon.vue'
import DocumentsIcon from '@/components/icons/DocumentsIcon.vue'

const { t } = useI18n()

const advantagesList = [
  {
    icon: AvailableTruck,
    title: t('home.requestOfferTitle'),
    description: t('home.requestOffer[0]'),
    descriptionBold: t('home.requestOffer[1]'),
    descriptionFinal: t('home.requestOffer[2]'),
  },
  {
    icon: BookingIcon,
    title: t('home.vehiclesAvailableTitle'),
    description: t('home.vehiclesAvailable[0]'),
    descriptionBold: t('home.vehiclesAvailable[1]'),
    descriptionFinal: t('home.vehiclesAvailable[2]'),
  },
  {
    icon: FlexibleIcon,
    title: t('home.getPrivilegeTitle'),
    description: t('home.getPrivilege[0]'),
    descriptionBold: t('home.getPrivilege[1]'),
    descriptionFinal: t('home.getPrivilege[2]'),
  },
  {
    icon: DocumentsIcon,
    title: t('home.rentOrLeaseTitle'),
    description: t('home.rentOrLease'),
  },
]
</script>
