<template>
  <svg
    width="30"
    height="25"
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.352 0.600098C3.456 4.0561 0 9.7201 0 15.9601C0 21.0481 3.072 24.0241 6.624 24.0241C9.984 24.0241 12.48 21.3361 12.48 18.1681C12.48 15.0001 10.272 12.6961 7.392 12.6961C6.816 12.6961 6.048 12.7921 5.856 12.8881C6.336 9.6241 9.408 5.7841 12.48 3.8641L8.352 0.600098ZM24.864 0.600098C20.064 4.0561 16.608 9.7201 16.608 15.9601C16.608 21.0481 19.68 24.0241 23.232 24.0241C26.496 24.0241 29.088 21.3361 29.088 18.1681C29.088 15.0001 26.784 12.6961 23.904 12.6961C23.328 12.6961 22.656 12.7921 22.464 12.8881C22.944 9.6241 25.92 5.7841 28.992 3.8641L24.864 0.600098Z"
      fill="#FC554A"
    />
  </svg>
</template>
