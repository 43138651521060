<template>
  <div class="w-full bg-white">
    <div class="px-4 sm:px-5 md:px-6 lg:px-8">
      <VHeader :title="$t('index.locations.title')" />
    </div>
    <div
      class="px-4 sm:px-5 md:px-6 lg:px-8 grid grid-cols-[repeat(6,_192px)] py-4 mt-8 overflow-x-auto gap-x-4 lg:justify-center no-scrollbar"
    >
      <figure v-for="city in CITIES" :key="city.image" class="cursor-pointer">
        <NuxtImg
          :alt="city.title"
          :src="city.image"
          width="12rem"
          height="10rem"
          class="object-fill bg-gray-500 rounded-lg min-h-40"
          @click="navigateTo('/request-quote')"
        />
        <figcaption
          class="mt-2 text-lg font-bold leading-6 md:text-center max-w-44 lg:text-xl md:mx-auto"
        >
          {{ city.title }}
        </figcaption>
      </figure>
    </div>
  </div>
</template>
<script setup lang="ts">
const CITIES = [
  { title: 'Nordrhein-Westfalen', image: 'westfalen.jpg' },
  { title: 'Bayern', image: 'bayern.jpg' },
  { title: 'Hessen', image: 'hessen.jpg' },
  { title: 'Baden-Württemberg', image: 'baden.jpg' },
  { title: 'Niedersachsen', image: 'niedersachsen.jpg' },
  { title: 'Rheinland-Pfalz', image: 'pfalz.png' },
]
</script>
