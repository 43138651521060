<template>
  <div class="flex justify-center">
    <VButton
      class="flex items-center w-auto h-10 p-8"
      to="/request-quote"
      :size="Size.sm"
      @click.prevent="onTrackEvent"
    >
      <span class="font-semibold md:text-lg">{{
        $t('index.hero.findVehicle')
      }}</span>
    </VButton>
  </div>
</template>

<script setup lang="ts">
import { Size } from '@/types/enums'

function onTrackEvent() {
  useNuxtApp().$trackEvent('home.hero_cta.clicked')
}
</script>
