<template>
  <section
    class="relative flex items-end justify-between bg-cover h-[500px] md:h-[670px] bg-[url('/public/become_rental_company_banner_mobile.png')] md:bg-[url('/public/become_rental_company_banner.png')] bg-center md:bg-[30%_center] p-8 md:p-8"
  >
    <div class="absolute inset-0 bg-cblack opacity-60"></div>

    <div
      class="relative flex items-center flex-1 max-w-screen-xl px-4 py-12 mx-auto md:px-12"
    >
      <div class="w-full space-y-6 text-white md:w-2/3 md:max-w-lg">
        <h2 class="text-3xl font-bold md:text-5xl">
          {{ $t('home.becomeSupplier[0]') }}
          <span class="text-cred">{{ $t('home.becomeSupplier[1]') }}</span>
        </h2>
        <ul class="space-y-3 md:space-y-1 md:text-lg">
          <li
            v-for="item in rentalPrivileges"
            :key="item"
            class="flex items-center"
          >
            <CheckmarkIcon class="w-5 h-5 mr-4 text-cred" />
            {{ item }}
          </li>
        </ul>

        <div
          class="flex flex-col-reverse space-y-4 space-y-reverse md:flex-row md:space-y-0 md:space-x-4"
        >
          <VButton
            :size="Size.md"
            class="w-full md:w-1/2"
            @click="navigateTo('/kontakt')"
            >{{ $t('requestNow') }}</VButton
          >
          <VButton
            :size="Size.md"
            :variant="Variant.secondaryLink"
            class="text-left md:w-1/2"
            @click="navigateTo('/vermieten')"
            >{{ $t('moreInfo') }}</VButton
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { Size, Variant } from '@/types/enums'
import CheckmarkIcon from '@/components/icons/CheckmarkIcon.vue'

const rentalPrivileges = [
  'Reichweite: 1000+ Mietinteressenten',
  'Lukrativ: Umsatz statt Kosten',
  'Digital: Papierlose Prozesse',
]
</script>
