<template>
  <div
    class="fixed z-10 bottom-6 right-5"
    :class="{ 'md:sticky flex justify-end md:pr-5': isAtScrollThreshold }"
  >
    <button
      id="backToTop"
      type="button"
      class="p-3 mb-5 mr-5 bg-white border-2 border-transparent border-gray-200 rounded-full shadow-full group hover:bg-cred focus:outline-none focus:ring-2 focus:ring-cblack focus:ring-offset-2"
      @click="scrollToTop"
    >
      <ArrowUpIcon
        class="w-6 h-6 text-cred group-hover:text-white"
        aria-hidden="true"
      />
    </button>
  </div>
</template>
<script setup>
import ArrowUpIcon from '@/components/icons/ArrowUpIcon.vue'

const isAtScrollThreshold = ref(false)

onMounted(() => {
  window.addEventListener('scroll', checkScrollThreshold)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', checkScrollThreshold)
})

function checkScrollThreshold() {
  isAtScrollThreshold.value =
    window.innerHeight + window.scrollY >= document.body.offsetHeight - 600
}

function scrollToTop() {
  if (window !== undefined) window.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>
